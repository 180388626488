@import"../../../assets/Scss/media-queries.scss";
// @include lt-xxl {}

.main {
  height: 600px;
  border: solid 1px rgba(75, 70, 92, 0.12);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include lt-xxl {
    height: 400px;
  }
}

.seenBg {
  background-color: yellow;
}

.unseenBg {
  background-color: gray;
}


.users_list {
  height: 100%;
  width: 33%;
  border-right: solid 1px rgba(75, 70, 92, 0.12);
  display: flex;
  flex-direction: column;

  .top_side {
    box-sizing: border-box;
    height: 13%;
    padding: 0px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 1px rgba(75, 70, 92, 0.12);

    .input_group {
      margin: 0px 0px 0px 3px;
    }

    .avatar {
      flex: 1 1 auto;
      transform: scale(0.9);
    }
  }

  .BottomSide {
    height: 87%;
    padding: 5px 5px;
    overflow: scroll;
    flex-grow: 1;

    ::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }

    .dialItem {
      margin: 10px 0px;
      padding: 10px;
      border-radius: 5px;
    }
    .dialItem:hover {
      cursor: pointer;
      background-color: #f7f6f6;
    }
  }
}

.conv_container {
  height: 100%;
  width: 67%;
  background-color: #f7f6f6;
  border-radius: 5px;
}

.noComment {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .buble {
    width: 110px;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 500px;
    box-shadow: 0px 0px 12px rgba($color: #000000, $alpha: 0.06);

    img {
      width: 50%;
    }
  }

  p {
    margin: 20px 0px 0px 0px;
    background-color: white;
    border-radius: 500px;
    padding: 6px 20px;
    box-shadow: 0px 0px 12px rgba($color: #000000, $alpha: 0.06);
    font-size: 16px;
    font-weight: 500;
  }
}

.BottomSide::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.max1 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;
}

.Conversation {
  display: flex;
  height: 100%;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;

  .topside {
    width: 100%;
    box-sizing: border-box;
    height: 13%;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 1px rgba(75, 70, 92, 0.12);
  }

  .bodySide {
    height: 75%;
    width: 100%;
    flex-grow: 1;
    position: relative;
    padding: 15px;
    overflow: auto;
    scroll-behavior: smooth;
    .msg_container {
      position: relative;
      width: 100%;
      margin: 10px 0px;
      display: flex;
      flex-direction: column;
    }
  
    .msg {
      max-width: 80%;
      word-wrap: break-word;
      overflow-wrap: break-word;
      margin: 0px;
      height: fit-content;
      width: fit-content;
      padding: 8px 15px;
      background-color: white;
      border-radius: 5px;
      box-shadow: 0px 0px 12px rgba($color: #000000, $alpha: 0.06);
    }
  
    .left {
      align-items: flex-start;
      justify-content: flex-start;
      .msg {
        background-color: #f4ba41ff;
        color: white;
      }
    }
  
    .right {
      align-items: flex-end;
      justify-content: flex-end;
    }
  }
  
  .botSide {
    width: 100%;
    box-sizing: border-box;
    height: 12%;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: solid 1px rgba(75, 70, 92, 0.12);
  }
}
